import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from './../../constants'

export const styles = makeStyles(theme => ({
  container: {
    paddingTop: 60,
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    container: {
      paddingTop: 120,
    },
  },
}))
