import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 960,
    margin: '0px auto',
  },
  logo: {
    display: 'block',
    marginBottom: 40,
  },
  button: {
    maxWidth: 460,
  },
}))
