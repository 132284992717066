import * as React from 'react'
import Layout from '../layouts/defaultLayout'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './../pagesStyles/notFoundStyles'
import { Typography } from '@material-ui/core'
import CHRButton from '../components/button'
import CHRContentContainer from '../components/contentContainer'
import CHRSectionContainer from '../components/sectionContainer'
import chirpyestIcon from './../assets/images/chirpyest-logo.svg'
import { navigate } from 'gatsby'
import { ROUTES } from '../constants'

interface NotFoundPageProps {
  t: TFunction
  error?: string
}

const NotFoundPage = ({ t, error }: NotFoundPageProps) => {
  const classes = styles()

  return (
    <Layout>
      <CHRSectionContainer>
        <CHRContentContainer>
          <div className={classes.container}>
            <img
              src={chirpyestIcon}
              alt={t('shared.chirpyest')}
              className={classes.logo}
            />
            <Typography variant="h1" align="center">
              {error ? error : t('notFound.title')}
            </Typography>
            <CHRButton
              label={t('notFound.buttonText')}
              onClick={() => navigate(ROUTES.home)}
              customStyle={classes.button}
            />
          </div>
        </CHRContentContainer>
      </CHRSectionContainer>
    </Layout>
  )
}
export default withTranslation()(NotFoundPage)
