import * as React from 'react'
import { styles } from './styles'

interface SectionContainerProps {
  children: React.ReactNode
  customStyle: any
}

const CHRSectionContainer = (props: SectionContainerProps) => {
  const { children, customStyle = '' } = props
  const classes = styles()

  return <div className={`${classes.container} ${customStyle}`}>{children}</div>
}

export default CHRSectionContainer
